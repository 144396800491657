<template>
  <div>
    <p class="intro">
      学科评估是教育部学位与研究生教育发展中心（简称学位中心）按照国务院学位委员会和教育部颁布的《学位授予与人才培养学科目录》（简称学科目录）对全国具有博士或硕士学位授予权的一级学科开展整体水平评估。学科评估是学位中心以第三方方式开展的非行政性、服务性评估项目，2002年首次开展，截至2017年完成了四轮。
      第四轮学科评估于2016年4月启动，按照“自愿申请、免费参评”原则，采用“客观评价与主观评价相结合”的方式进行。评估体系在前三轮的基础上进行诸多创新；评估数据以“公共数据和单位填报相结合”的方式获取；评估结果按“分档”方式呈现，具体方法是按“学科整体水平得分”的位次百分位，将前70%的学科分9档公布：前2%（或前2名）为A+，2%～5%为A（不含2%，下同），5%～10%为A-，10%～20%为B+，20%～30%为B，30%～40%为B-，40%～50%为C+，50%～60%为C，60%～70%为C-。
      公布评估结果旨在为参评单位了解学科优势与不足、促进学科内涵建设、提高研究生培养质量提供客观信息；为学生选报学科、专业提供参考；同时也便于社会各界了解我国高校和科研单位学科内涵建设的状况和成效。
    </p>
    <div style="
            width: 20%;
            display: inline-block;
            vertical-align: top;
            font-size: 16px;
          ">
      <el-menu default-active="2" class="el-menu-vertical-demo" unique-opened>
        <el-submenu v-for="(item, i) in assess" :key="i" :index="toString(i)">
          <template slot="title">
            <span class="font-16">{{ item.name }}</span>
          </template>
          <el-menu-item v-for="(val, key) in item.childrens" :index="childString(i, key)" :key="key" class="font-16" @click="getAssesData(val.code)">{{ val.name }}</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <div style="
            width: 75%;
            display: inline-block;
            margin-left: 5%;
            vertical-align: top;
          ">
      <el-table :data="tableData" border style="width: 100%; font-size: 16px">
        <el-table-column type="index" label="序号" width="60" />
        <el-table-column prop="scode" label="学校代号" width="180" />
        <el-table-column prop="sname" label="学校名称" width="180" />
        <el-table-column prop="result" label="评选结果" />
      </el-table>
    </div>
    <div style="text-align: right; color: gray; margin-top: 20px">
      *引用于教育部
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      assess: {},
      tableData: [],
    };
  },
  
  created() {
    this.getAssessList();
    this.getAssesData(301);
  },
  methods: {
    toString(i) {
      return String(i);
    },
    childString(i, k) {
      return i + "-" + k;
    },
    getAssesData(code) {
      this.$fecth.post("assess/getAssessData", { code: code }).then((res) => {
        this.tableData = res;
      });
    },
    getAssessList() {
      this.$fecth.post("assess/getList").then((res) => {
        this.assess = res;
      });
    },
  },
};
</script>

<style scoped lang='less'>
.intro {
  text-indent: 2rem;
  color: gray;
  margin: 30px 0;
  line-height: 150%;
}
</style>
