<template>
  <div>
    <p class="intro">
      特色专业是指一所学校的某一专业，在教育目标、师资队伍、课程体系、教学条件和培养质量等方面，具有较高的办学水平和鲜明的办学特色，已产生较好的办学效益和社会影响，是一种高标准、高水平、高质量的专业。我国“十一五”期间将择优重点建设3000个左右的特色专业建设点。
    </p>
    <div style="
            width: 20%;
            display: inline-block;
            vertical-align: top;
            font-size: 16px;
          ">
      <el-menu default-active="1" class="el-menu-vertical-demo" unique-opened @select="handleSelects">
        <el-menu-item index="1">
          <span class="font-16">第一批</span>
        </el-menu-item>
        <el-menu-item index="2">
          <span class="font-16">第二批</span>
        </el-menu-item>
        <el-menu-item index="3">
          <span class="font-16">第三批</span>
        </el-menu-item>
        <el-menu-item index="4">
          <span class="font-16">第四批</span>
        </el-menu-item>
        <el-menu-item index="5">
          <span class="font-16">第五批</span>
        </el-menu-item>
        <el-menu-item index="6">
          <span class="font-16">第六批</span>
        </el-menu-item>
        <el-menu-item index="7">
          <span class="font-16">第七批</span>
        </el-menu-item>
      </el-menu>
    </div>

    <div style="
            width: 75%;
            display: inline-block;
            margin-left: 5%;
            vertical-align: top;
          ">
      <el-table :data="test" border style="width: 100%; font-size: 16px">
        <el-table-column type="index" label="序号" width="60" />
        <el-table-column prop="name" label="学校名称" width="180" />
        <el-table-column prop="specialty" label="专业名称" />
        <el-table-column prop="school_id" label="在豫招生代号" width="140" />
        <el-table-column prop="specialty_id" label="专业代号（不可用于填报）" width="140" />
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      test: [],
      tszy: 1,
    };
  },
  
  created() {
    this.getTszy();
  },
  methods: {
    handleSelects(key) {
      this.tszy = key;
      this.getTszy();
    },
    getTszy() {
      this.$fecth.post("tszy/getData", { type: this.tszy }).then((res) => {
        this.test = res;
      });
    },
  },
};
</script>

<style scoped lang='less'>
.intro {
  text-indent: 2rem;
  color: gray;
  margin: 30px 0;
  line-height: 150%;
}
</style>
