<template>
  <div class="MojarNav">
    <el-row>
      <el-col :span='5'>
        <div class="line title">学科门类</div>
        <div :class="{ line: true, act: levelId == item.specialty_code }" v-for="(item, i) in level" :key="i"
          @click="getChildren(item.specialty_code)">{{ item.name }}</div>
      </el-col>
      <el-col :span='5'>
        <div class="line title">专业类</div>
        <div v-loading=loading :class="{ line: true, act1: childId == item.specialty_code }" v-for="(item, i) in children"
          :key="i" @click="getContent(item)">{{ item.name }}</div>
      </el-col>
      <el-col :span='14'>
        <div class="line title"><span>专业名称</span> <span>专业代号</span> <span>专业详情</span></div>
        <div v-loading=loading class="line" v-for="(item, i) in childrenList" :key="i">
          <span>{{ item.name }}</span>
          <span>{{ item.specialty_code }}</span>
          <span>
            <router-link :to="{
              path: '/specialtyInfo',
              query: { sid: item.specialty_code, degree: item.degree }
            }">查看
            </router-link>
          </span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    type: Number,
  },
  data() {
    return {
      level: [],
      children: [],
      childrenList: [],
      levelId: "",
      childId: "",
      loading: false,
    };
  },

  watch: {
    type() {
      this.getLevel(this.type);
    },
  },
  created() {
    this.getLevel(0);
  },
  methods: {
    getLevel(id) {
      if (id == 4) id = 2;
      this.$fecth.post("Specialty/getSpecialty", { degree: id }).then((res) => {
        this.level = res;
        this.getChildren(res[0].specialty_code);
      });
    },
    getChildren(id) {
      this.levelId = id;
      this.loading = true;
      this.$fecth
        .post("Specialty/getSpecialtyChild", {
          specialty_code: id,
        })
        .then((res) => {
          this.children = res;
          this.childrenList = this.children[0].childrens;
          this.childId = this.children[0].specialty_code;
          this.loading = false;
        });
    },
    getContent(item) {
      this.childId = item.specialty_code;
      this.childrenList = item.childrens;
    },
  },
};
</script>



<style scoped lang='less'>
.MojarNav {
  margin-bottom: 20px;

  .title {
    font-size: 18px !important;
    color: #000000;
    background: #f0fbff;
    line-height: 60px !important;
  }

  .line {
    padding: 0 20px;
    border: 1px solid #e6e6e6;
    font-size: 16px;
    color: #404d4d;
    line-height: 48px;
    border-bottom: 0;

    span {
      display: inline-block;
      width: 32%;

      a {
        color: #1787e0;
      }

      text-align: center;

      &:first-child {
        text-align: left;
      }
    }

    &:last-child {
      border-bottom: 1px solid #e6e6e6;
    }
  }
}

.act {
  border-left: 5px solid #1787e0 !important;
  background: #f5f8fa;
}

.act1 {
  border-left: 5px solid #1787e0 !important;
  color: #1787e0 !important;
}</style>
