<template>
  <div class="page">
    <el-row type="flex" align="middle">
      <el-col :span="12">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>智能查询</el-breadcrumb-item>
          <el-breadcrumb-item>查专业</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>

      <el-col :span="7" :offset="5">
        <div class="search">
          <el-autocomplete v-model="kwd" style="width:220px" :fetch-suggestions="querySearch" :trigger-on-focus="false"
            class="condition-search" prefix-icon="el-icon-search" popper-class="my-autocomplete" placeholder="请输入内容"
            @keyup.native.enter="toSearch" @select="handleSelect">
            <template slot-scope="{ item }">
              <div class="name">{{ item.name }}</div>
              <span class="addr">{{ item.address }}</span>
            </template>
          </el-autocomplete>
        </div>
        <div
          style="display: inline-block;background:#1787e0;border: 1px solid rgb(220, 223, 230); border-radius:0 4px 4px 0height: 40px;width: 50px;line-height: 40px;position: relative;left: 50px;">
          <img style="margin-left: 16px;vertical-align: middle;" src="@/assets/img/mojar/mj-search.png" alt="" />
        </div>
      </el-col>
    </el-row>

    <div class="brief">说明： 1. 本目录是在《普通高等学校本科专业目录（2012年）》基础上，增补近几年批准增设的目录外新专业而形成。2. 特设专业在专业代号后加T表示；国家控制布点专业在专业代号后加K表示。
    </div>

    <div class="tabs">
      <span :class="{ tab: true, act: degree === 0 }" @click="getLevel(0)">本科</span>
      <span :class="{ tab: true, act: degree === 1 }" @click="getLevel(1)">专科</span>
      <span :class="{ tab: true, act: degree === 4 }" @click="getLevel(4)">职业本科</span>
      <span :class="{ tab: true, act: degree === 3 }" @click="getLevel(3)">特色专业建设名单</span>
      <span :class="{ tab: true, act: degree === 2 }" @click="getLevel(2)">学科评估(2017)</span>
    </div>

    <mojar-nav v-if="degree == 0 || degree == 1 || degree == 4"  :type=degree />
    <mojar-characteristic v-if="degree == 3" :type=degree />
    <subject-review v-if="degree == 2" :type=degree />

  </div>
</template>

<script>
import MojarNav from "./components/MojarNav";
import MojarCharacteristic from "./components/MojarCharacteristic";
import SubjectReview from "./components/SubjectReview";
export default {
  name: "LookSpecialty",
  data() {
    return {
      kwd: "",
      degree: 0,
      act: "",
    };
  },
  components: {
    MojarNav,
    MojarCharacteristic,
    SubjectReview,
  },


  methods: {
    handleSelect(item) {
      this.kwd = item.name;
      this.$router.push({
        path: "/specialtyInfo",
        query: { sid: item.specialty_code },
      });
    },
    querySearch(queryString, cb) {
      if (queryString.trim()) {
        this.$fecth
          .get("specialty/searchSpecialtyName", {
            params: { name: queryString },
          })
          .then((res) => {
            if (res.lists.length > 0) {
              // 调用 callback 返回建议列表的数据
              cb(res.lists);
            }
          });
      }
    },
    getLevel(id) {
      this.degree = id;
    },
    toSearch() {
      this.$router.push({ path: "/search", query: { kwd: this.kwd, type: 1 } });
    },
  },
};
</script>

<style scoped lang='less'>
.search {
  width: 40%;
  display: inline-block;
}

.brief {
  font-size: 12px;
  color: #808080;
  height: 40px;
  background: #d4f2ff;
  line-height: 40px;
  padding: 0 20px;
  border: 1px solid #b6e0ff;
  border-radius: 2px;
}

.tabs {
  color: #666666;
  padding: 25px 0;
  font-size: 18px;

  span {
    display: inline-block;
    padding: 0 15px;
    line-height: 35px;
    border: 1px solid #55aeff;
    margin-right: 20px;
    border-radius: 2px;

    &:hover {
      background: #1787e0;
      color: white;
    }
  }
}

.act {
  background: #1787e0;
  color: white;
}
</style>
